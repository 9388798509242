import React from 'react';
import {getRoutes} from "../components/shared/routes";
import Layout from "../components/shared/Layout";
import SEO from "../components/shared/SEO";
import PageTitle from "../components/shared/PageTitle/PageTitle";
import CookieSettings from "../components/shared/CookieSettings/CookieSettings";
import Footer from "../components/shared/Footer/Footer";

const CookieSettingsPage = () => {
    const pageTitle = getRoutes().CookieSettings.pageTitle;

    return (
        <Layout>
            <SEO title={pageTitle}/>

            <PageTitle>{pageTitle}</PageTitle>

            <CookieSettings/>

            <Footer />
        </Layout>
    )
}

export default CookieSettingsPage;