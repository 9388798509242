import React, { useState, useEffect } from 'react';
import * as css from './cookieSettings.module.scss';
import AppCheckbox, {checkboxVariants} from "../formControls/AppCheckbox";

export const getCookie = (name) => {
   const nameEQ = `${name}=`;
   const ca = document.cookie.split(';');
   for (let i = 0; i < ca.length; i++) {
       let c = ca[i];
       while (c.charAt(0) === ' ') c = c.substring(1, c.length);
       if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
   }
   return null;
};

const CookieSettings = (props) => {
   const [cookies, setCookies] = useState({
       analytics: false,
       marketing: false,
   });

   const extractSettings = (cookieString) => {
       const defaultSettings = { analytics: false, marketing: false };
       const settings = {};

       settings.analytics = !!cookieString.includes('"analytics":true');
       settings.marketing = !!cookieString.includes('"marketing":true');

       return { ...defaultSettings, ...settings };
   }


   useEffect(() => {
       const cookieConsent = getCookie('cookieConsent')?.toString();
       if (cookieConsent === 'true') {
           try {
               const savedCookies = JSON.parse(getCookie('cookieSettings') || '{}');
               setCookies(savedCookies);
               applyCookies(savedCookies);
           } catch (error){
               console.error('Error parsing cookie settings:', error);
               const cookieString = getCookie('cookieSettings') || '';
               const nonFullCookie = extractSettings(cookieString);
               setCookies(nonFullCookie)
               applyCookies(nonFullCookie)
           }
       }
   }, []);


   const setCookie = (name, value, days) => {
       const date = new Date();
       date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
       const expires = `expires=${date.toUTCString()}`;
       document.cookie = `${name}=${value};${expires};path=/`;
   };


   const handleToggleCookie = (cookieName) => {
       setCookies({
           ...cookies,
           [cookieName]: !cookies[cookieName],
       });
   };


   const saveCookieSettings = () => {
       setCookie('cookieSettings', JSON.stringify(cookies), 365);
       applyCookies(cookies);
       window.location.reload()
   };


   const applyCookies = (cookieSettings) => {
       setCookie('cookieConsent', 'true', 365);
       if (cookieSettings.analytics.toString() === 'true') {
           cookieSettings["analytics"] = true;
           setCookie('cookieSettings', JSON.stringify(cookieSettings), 365);
       } else {
           cookieSettings["analytics"] = false;
           setCookie('cookieSettings', JSON.stringify(cookieSettings), 365);
       }


       if (cookieSettings.marketing.toString() === 'true') {
           cookieSettings["marketing"] = true;
           setCookie('cookieSettings', JSON.stringify(cookieSettings), 365);
       } else {
           cookieSettings["marketing"] = false;
           setCookie('cookieSettings', JSON.stringify(cookieSettings), 365);
       }
   };


   const cookiesModal = () => {
       return (
           <div className={css.modal}>
               <div className={css.modalContent}>
                   <h2>Cookies management</h2>
                   <div className={css.cookieOption}>
                       <AppCheckbox
                           label={"Essential"}
                           checked={true}
                           disabled={true}
                           variant={checkboxVariants.default}
                       />
                       <p>
                           Necessary for the correct operation of the website.
                       </p>
                   </div>
                   <div className={css.cookieOption}>
                       <AppCheckbox
                           label={"Analytics"}
                           checked={cookies.analytics}
                           onChange={() => handleToggleCookie("analytics")}
                           variant={checkboxVariants.default}
                       />
                   </div>
                   <div className={css.cookieOption}>
                       <AppCheckbox
                           label={"Marketing"}
                           checked={cookies.marketing}
                           onChange={() => handleToggleCookie("marketing")}
                           variant={checkboxVariants.default}
                       />
                   </div>
                   <button onClick={saveCookieSettings} className={css.cookieBtn}>Save</button>
               </div>
           </div>
       )
   }


   return (
       cookiesModal()
   );
};

export default CookieSettings;